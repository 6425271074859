<template>
	<div id="Shutters" :style="style">
		<MyHeader />
		<!-- Learn More About Shutters -->
		<div class="block block1">
			<p>All our shutters are custom made by skilfully hand-made of quality craftsmen.
				We offer a wide range of materials, paint finishing, styles, colours,
				with our design options you can create and customize your shutters exactly as you want. </p>
		</div>
		<!-- Wood Shutters -->
		<div class="block block2">
			<h3>Wood Shutters</h3>
			<div class="content">
				<img v-if="productList['Wood Shutters'] && productList['Wood Shutters'][0]"
					:src="productList['Wood Shutters'][0].productImageUrl"
					@click="largerVersion(productList['Wood Shutters'][0].productImageUrl)" />
				<img v-else src="@/assets/images/wood-01.png" @click="largerVersion('@/assets/images/wood-01.png')" />
				<div class="right">
					<p>To make your home with the luxury and elegance a real wood shutter is a good choice.
						Our wood shutters are available in Paulownia, poplar and basswood timber,
						with competitive price with high quality our paulownia shutters have been market leading in the
						world.</p>
					<img v-if="productList['Wood Shutters'] && productList['Wood Shutters'][1]"
						:src="productList['Wood Shutters'][1].productImageUrl"
						@click="largerVersion(productList['Wood Shutters'][1].productImageUrl)" />
					<img v-else src="@/assets/images/wood-02.png"
						@click="largerVersion('@/assets/images/wood-02.png')" />
				</div>
			</div>
		</div>
		<!-- PVC Shutters -->
		<div class="block block3">
			<h3>PVC Shutters</h3>
			<p>PVC shutter is water resistant and suitable for all your windows,
				especially ideal for your kitchen or bathroom.</p>
			<div class="content">
				<div class="left">
					<img v-if="productList['PVC Shutters'] && productList['PVC Shutters'][1]"
						:src="productList['PVC Shutters'][1].productImageUrl"
						@click="largerVersion(productList['PVC Shutters'][1].productImageUrl)" />
					<img v-else src="@/assets/images/PVC-02.png" @click="largerVersion('@/assets/images/PVC-02.png')" />
				</div>
				<div class="right">
					<img v-if="productList['PVC Shutters'] && productList['PVC Shutters'][0]"
						:src="productList['PVC Shutters'][0].productImageUrl"
						@click="largerVersion(productList['PVC Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/PVC-01.png" @click="largerVersion('@/assets/images/PVC-01.png')" />
				</div>
			</div>
		</div>
		<!-- Special shapes for you -->
		<div class="block block4">
			<h3>Special shapes for you</h3>
			<p>Almost any shaped windows, round, oval, angled, or octagonal windows are available,
				or even for some irregular shaped window we can produce as per the templates provided.</p>
			<div class="content">
				<div class="left">
					<img v-if="productList['Special shapes for you'] && productList['Special shapes for you'][0]"
						:src="productList['Special shapes for you'][0].productImageUrl"
						@click="largerVersion(productList['Special shapes for you'][0].productImageUrl)" />
					<img v-else src="@/assets/images/special-02.png"
						@click="largerVersion('@/assets/images/special-02.png')" />
				</div>
				<div class="right">
					<img v-if="productList['Special shapes for you'] && productList['Special shapes for you'][1]"
						:src="productList['Special shapes for you'][1].productImageUrl"
						@click="largerVersion(productList['Special shapes for you'][1].productImageUrl)" />
					<img v-else src="@/assets/images/special-01.png"
						@click="largerVersion('@/assets/images/special-01.png')" />
				</div>
			</div>
		</div>
		<!-- Tracked Shutters -->
		<div class="block block4x Tracked">
			<h3>Tracked Shutters</h3>
			<p>Bi-fold or by-pass, we have both options for your window style.</p>
			<div class="content">
				<div class="right">
					<img v-if="productList['Tracked Shutters'] && productList['Tracked Shutters'][1]"
						:src="productList['Tracked Shutters'][1].productImageUrl"
						@click="largerVersion(productList['Tracked Shutters'][1].productImageUrl)" />
					<img v-else src="@/assets/images/tracked-02.png"
						@click="largerVersion('@/assets/images/tracked-02.png')" />
				</div>
				<div class="left">
					<img v-if="productList['Tracked Shutters'] && productList['Tracked Shutters'][0]"
						:src="productList['Tracked Shutters'][0].productImageUrl"
						@click="largerVersion(productList['Tracked Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/tracked-01.png"
						@click="largerVersion('@/assets/images/tracked-01.png')" />
				</div>
			</div>
		</div>
		<!-- French style Shutters -->
		<div class="block block5">
			<div class="content">
				<div class="left">
					<h3>French style Shutters</h3>
					<p>Shutters are an excellent window treatment for french doors.
						It can be custom built to work with or around your door hardware.</p>
					<img v-if="productList['French style Shutters'] && productList['French style Shutters'][0]"
						:src="productList['French style Shutters'][0].productImageUrl"
						@click="largerVersion(productList['French style Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/french-01.png"
						@click="largerVersion('@/assets/images/french-01.png')" />
				</div>
				<div class="right">
					<h3>Crafted grained Shutters</h3>
					<p>Retro，textured，archaic style, to create your style.</p>
					<img v-if="productList['Crafted grained Shutters'] &&productList['Crafted grained Shutters'][0]"
						:src="productList['Crafted grained Shutters'][0].productImageUrl"
						@click="largerVersion(productList['Crafted grained Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/crafted.png"
						@click="largerVersion('@/assets/images/crafted.png')" />
				</div>
			</div>
		</div>
		<!-- Solid panels Shutters -->
		<div class="block block6">
			<div class="content">
				<div class="right">
					<h3>Stains Shutters</h3>
					<p>Nice wood grains are visible, more get closed to nature.</p>
					<img v-if="productList['Stains Shutters'] &&productList['Stains Shutters'][0]"
						:src="productList['Stains Shutters'][0].productImageUrl"
						@click="largerVersion(productList['Stains Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/stains.png" @click="largerVersion('@/assets/images/stains.png')" />
				</div>
				<div class="left">
					<h3>Solid panels Shutters</h3>
					<p>A classic period style, ideal to suit your window style.</p>
					<img v-if="productList['Solid panels Shutters'] && productList['Solid panels Shutters'][0]"
						:src="productList['Solid panels Shutters'][0].productImageUrl"
						@click="largerVersion(productList['Solid panels Shutters'][0].productImageUrl)" />
					<img v-else src="@/assets/images/solid-01.png"
						@click="largerVersion('@/assets/images/solid-01.png')" />
				</div>
			</div>
		</div>
		<!-- Custom Colors -->
		<div class="block block9">
			<h3>Custom Colors</h3>
			<p>Our shutters are available in a wide range of paint and stained or crafted grained finishes, from
				traditional white to modern black. You can select any colors from our colors swatches, or we can do custom
				colors options to finish your shutters in any colors of your choice.</p>
			<div class="custom_img">
				<img v-if="productList['Custom Colours'] && productList['Custom Colours'][0]"
					:src="productList['Custom Colours'][0].productImageUrl"
					@click="largerVersion(productList['Custom Colours'][0].productImageUrl)" />
				<img v-else src="@/assets/images/custom-01.png"
					@click="largerVersion('@/assets/images/custom-01.png')" />
				<img v-if="productList['Custom Colours'] && productList['Custom Colours'][1]"
					:src="productList['Custom Colours'][1].productImageUrl"
					@click="largerVersion(productList['Custom Colours'][1].productImageUrl)" />
				<img v-else src="@/assets/images/custom-02.png"
					@click="largerVersion('@/assets/images/custom-02.png')" />
			</div>
		</div>
		<MyFooter />
		<el-image-viewer v-if="showViewer" :url-list="imgList" :on-close="()=>{showViewer = false}" />
	</div>
</template>

<script>
	import * as Api from '@pm'
	import MyHeader from '@/components/MyHeader.vue'
	import MyFooter from '@/components/MyFooter.vue'
	export default {
		name: 'Shutters',
		components: {
			MyHeader,
			MyFooter,
			'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
		},
		data() {
			return {
				productList: {},
				style: {
					// height: '100vh',
					// overflow: 'hidden'
				},
				showViewer: false,
				imgList: []
			}
		},
		created() {
			this.getImages()
		},
		methods: {
			largerVersion(url) {
				this.imgList = []
				this.imgList.push(url)
				this.showViewer = true
			},
			getImages() {
				Api.getProductNameAndImages().then((result) => {
					if (result.data.code == 200) {
						let _obj = {}
						let _res = result.data.data || []
						_res.map((item) => {
							_obj[item.productName] = item.pproductImageList || []
							// _obj[item.productName] = item.pproductImageList[0] ? item.pproductImageList[0].productImageUrl : ''
							// _obj.push({ [item.productName]: item.pproductImageList[0] ? item.pproductImageList[0].productImageUrl : '' })
							return item
						})
						// debugger
						this.productList = _obj
						console.log(this.productList)
					} else {
						return false
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	#Shutters {
		.block {
			width: 1150px;
			margin: 0 auto;
		}

		.block1 {
			margin-top: 20px;

			p {
				padding: 0 20px;
				font-family: DINNextLTPro-Regular;
				color: #626262;
				margin: 0 auto;
				font-size: 15px;
				font-weight: 300;
				line-height: 30px;
			}
		}

		.block2 {
			margin-top: 40px;

			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 68px;
				color: #3b3b3b;
				text-align: center;
				margin-bottom: 20px;
				font-weight: normal;
			}

			.content {
				display: flex;
				// justify-content: center;
				align-items: flex-end;

				>img {
					width: 421px;
					height: 632px;
				}

				.right {
					width: 658px;
					font-size: 15px;
					font-weight: 300;
					line-height: 30px;
					color: #626262;
					margin-left: 17px;
					display: inline-flex;
					flex-direction: column;

					p {
						font-size: 15px;
						font-family: DINNextLTPro-Regular;
						line-height: 42px;
						color: #626262;
						margin-bottom: 40px;
					}

					img {
						width: 658px;
						height: 478px;
					}
				}
			}
		}

		.block3 {
			margin-top: 40px;

			h3 {
				font-family: DINNextLTPro-Medium;
				color: #3b3b3b;
				// margin: 80px auto 0;
				font-size: 48px;
				line-height: 68px;
				text-align: center;
				font-weight: normal;
			}

			p {
				font-family: DINNextLTPro-Regular;
				height: 40px;
				font-size: 15px;
				font-weight: 300;
				line-height: 30px;
				color: #626262;
				text-align: center;
			}

			.content {
				// justify-content: center;
				display: flex;
			}

			.left {
				width: 600px;

				img {
					width: 583px;
					height: 434px;
				}
			}

			.right {
				width: 497px;

				img {
					width: 497px;
					height: 434px;
				}
			}
		}

		.block4 {
			margin-top: 40px;

			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 68px;
				color: #3b3b3b;
				text-align: center;
				font-weight: normal;
				margin-bottom: 20px;
			}

			p {
				width: 1097px;
				font-size: 15px;
				font-family: DINNextLTPro-Regular;
				font-weight: 300;
				height: 60px;
				color: #626262;
				padding: 0 73px;
				text-align: center;
			}

			.content {
				display: flex;

				// justify-content: center;
				.left {
					img {
						width: 557px;
						height: 668px;
					}
				}

				.right {
					width: 526px;
					margin-left: 10px;

					img {
						width: 526px;
						height: 668px;
						margin-left: 3px;
					}
				}
			}
		}

		.block4x {
			margin-top: 40px;

			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 68px;
				color: #3b3b3b;
				text-align: center;
				font-weight: normal;
			}

			p {
				font-size: 15px;
				font-family: DINNextLTPro-Regular;
				font-weight: 300;
				line-height: 30px;
				height: 40px;
				color: #626262;
				text-align: center;
			}

			.content {
				display: flex;

				// justify-content: center;
				.left {
					img {
						width: 547px;
						height: 793px;
					}
				}

				.right {
					width: 547px;

					img {
						width: 523px;
						height: 793px;
						// margin-left: 20px;
					}
				}
			}
		}

		.block5 {
			margin-top: 40px;

			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 68px;
				color: #3b3b3b;
				text-align: right;
				font-weight: normal;
			}

			.content {
				display: flex;

				// justify-content: center;
				.left {
					h3 {
						font-size: 36px;
						font-family: DINNextLTPro-Medium;
						text-align: center;
						color: #3b3b3b;
					}

					p {
						width: 523px;
						height: 60px;
						font-size: 15px;
						font-family: '.PingFang SC';
						font-weight: 300;
						color: #626262;
						// width: 100%;
						text-align: center;
					}

					img {
						width: 523px;
						height: 670px;
					}
				}

				.right {
					width: 547px;
					margin-left: 25px;

					h3 {
						font-size: 36px;
						font-family: DINNextLTPro-Medium;
						text-align: center;
						color: #3b3b3b;
					}

					p {
						width: 100%;
						text-align: center;
						font-size: 15px;
						height: 60px;
						font-size: 15px;
						font-family: DINNextLTPro-Regular;
						font-weight: 300;
						color: #626262;
						// padding-left: 20px;
					}

					img {
						width: 548px;
						height: 670px;
					}
				}
			}
		}

		.block6 {
			margin-top: 40px;

			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 68px;
				color: #3b3b3b;
				text-align: right;
				font-weight: normal;
			}

			.content {
				display: flex;

				// justify-content: center;
				.left {
					margin-left: 25px;
					width: 545px;

					h3 {
						width: 545px;
						font-size: 36px;
						font-family: DINNextLTPro-Medium;
						text-align: center;
						color: #3b3b3b;
					}

					p {
						width: 545px;
						text-align: center;
						height: 40px;
						font-size: 15px;
						font-family: '.PingFang SC';
						font-weight: 300;
						color: #626262;
					}

					img {
						width: 545px;
						height: 408px;
					}
				}

				.right {
					width: 523px;

					h3 {
						width: 523px;
						font-size: 36px;
						font-family: DINNextLTPro-Medium;
						text-align: center;
						color: #3b3b3b;
					}

					p {
						font-size: 15px;
						height: 40px;
						font-size: 15px;
						font-family: DINNextLTPro-Regular;
						font-weight: 300;
						color: #626262;
						// padding-left: 20px;
						width: 523px;
						text-align: center;
					}

					img {
						width: 523px;
						height: 408px;
					}
				}
			}
		}

		.block9 {
			h3 {
				font-size: 48px;
				font-family: DINNextLTPro-Medium;
				line-height: 60px;
				color: #3b3b3b;
				margin: 40px auto 20px;
				font-weight: normal;
				text-align: center;
			}

			p {
				width: calc(523px + 545px + 25px);
				font-size: 15px;
				font-family: DINNextLTPro-Regular;
				font-weight: 300;
				line-height: 30px;
				height: 80px;
				color: #626262;
			}

			.custom_img {
				display: flex;
				align-items: center;
				// justify-content: center;
				margin-bottom: 120px;

				img {
					// width: 580px;
					// height: 435px;
					width: 523px;
					height: 376px;

					&:last-child {
						width: 545px;
						margin-left: 25px;
					}
				}
			}
		}

		.block,
		.block .left,
		.block .right {
			>img {
				object-fit: cover;
			}
		}
	}
</style>
<style lang="less">
	#Shutters {
		.el-form-item__error {
			top: 78px;
		}

		.el-input__inner {
			height: 100%;
			line-height: 100%;
			background-color: transparent;
		}

		.el-textarea__inner {
			min-height: 104px !important;
		}

		.el-input__inner,
		.el-textarea__inner {
			background-color: transparent;
			outline: none;
			box-shadow: none;
		}
	}
</style>
